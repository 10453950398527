import React from "react"
import * as S from "./stats-banner.styles"
import { Grid } from "@mui/material"

const StatsBanner = ({ title, description, stats }) => {
  if (!stats) return null
  return (
    <S.Wrapper contained maxWidth="xl">
      <S.CustomWrapper>
        {title && <S.Title>{title}</S.Title>}
        {description && (
          <S.Description className="main">{description}</S.Description>
        )}
        <Grid container spacing={{ xs: 4.5, sm: 12 }}>
          {stats.map(({ number, description }, index) => (
            <Grid key={`stat-${index}`} item xs={12} sm={4} md={4}>
              <S.StatWrapper>
                <S.Stat>{number}</S.Stat>
                <S.Description>{description}</S.Description>
              </S.StatWrapper>
            </Grid>
          ))}
        </Grid>
      </S.CustomWrapper>
    </S.Wrapper>
  )
}

export default StatsBanner
