import React from "react"
import * as S from "./admin-portal.styles"
import { Grid } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"

const AdminPortal = ({ title, cards }) => {
  const getCard = card => {
    switch (card.layout) {
      case "background":
        return (
          <Grid item xs={12} key={title}>
            <S.BgImage img={card?.image}>
              <S.Content className={card.layout}>
                {card?.title && <S.CardTitle>{card?.title}</S.CardTitle>}
                {card?.description && (
                  <S.CardDescription>{card?.description}</S.CardDescription>
                )}
              </S.Content>
            </S.BgImage>
          </Grid>
        )
      case "vertical":
        return (
          <Grid item xs={12} sm={6} key={title}>
            <S.Content className={card.layout}>
              {card?.title && <S.CardTitle>{card?.title}</S.CardTitle>}
              {card?.description && (
                <S.CardDescription>{card?.description}</S.CardDescription>
              )}
              {card?.link && (
                <CustomLink url={card?.link?.url}>
                  {card?.link?.title}
                </CustomLink>
              )}
              <S.ImageWrapper>
                {card?.video && (
                  <S.CustomVideo autoPlay muted loop playsInline>
                    <source src={card?.video?.mediaItemUrl} type="video/mp4" />
                  </S.CustomVideo>
                )}
                <S.Image className="vertical" img={card?.image} />
              </S.ImageWrapper>
            </S.Content>
          </Grid>
        )
      case "horizontal":
      default:
        return (
          <Grid item xs={12} key={title}>
            <S.Content className="horizontal">
              <S.Image img={card?.image} className="horizontal" />
              <S.InnerWrapper className="horizontal">
                {card?.title && <S.CardTitle>{card?.title}</S.CardTitle>}
                {card?.description && (
                  <S.CardDescription>{card?.description}</S.CardDescription>
                )}
              </S.InnerWrapper>
              {card?.link && (
                <CustomLink url={card?.link?.url}>
                  {card?.link?.title}
                </CustomLink>
              )}
            </S.Content>
          </Grid>
        )
    }
  }
  return (
    <S.Wrapper contained maxWidth="md">
      <S.Title>{title}</S.Title>
      <Grid container spacing={3}>
        {cards?.map(item => getCard(item))}
      </Grid>
    </S.Wrapper>
  )
}

export default AdminPortal
