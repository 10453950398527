import styled from "@emotion/styled"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  position: relative;
  padding-top: 1.25rem;
  padding-bottom: 5rem;
  background: ${({ theme }) => theme.palette.text.secondary};
`
export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  ::after {
    content: "";
    position: absolute;
    border-radius: 32px;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 7px);
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
  }
`
export const Video = styled.video`
  width: 100%;
  border-radius: 32px;
  position: relative;
  height: 100%;
  min-height: 632px;
  object-fit: cover;
`
export const Content = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 1.5rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  z-index: 1;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    left: 3rem;
    max-width: 689px;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
  .transparent {
    background: rgba(255, 255, 255, 0.15);
    gap: 0.75rem;
  }
  .right {
    font-size: ${({ theme }) => theme.typography.pxToRem(17)};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.17px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
    align-items: center;
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 450;
  margin-bottom: 2.5rem;
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(52)};
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 4rem;
    line-height: 112.5%;
  }
`
