import React from "react"
import * as S from "./logos-slider.styles"
import { Container, Stack, useMediaQuery, useTheme } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import AutoplaySwiper from "../../components/autoplay-swiper/autoplay-swiper.component"
// Import Swiper styles
import "swiper/css"

const LogosSlider = ({ logos, image, video }) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })
  if (!logos) return null
  return (
    <S.Wrapper>
      <Container maxWidth="md" style={{ position: "relative" }}>
        <S.ImageWrapper>
          <video autoPlay muted loop playsInline>
            <source src={video?.mediaItemUrl} type="video/mp4" />
          </video>
          <CustomImage className="image" img={image} />
        </S.ImageWrapper>
      </Container>
      <Stack spacing={{ xs: 4, sm: 10 }}>
        <AutoplaySwiper images={logos} reverseDirection />
        <AutoplaySwiper images={logos} />
        {!isXs && <AutoplaySwiper images={logos} />}
      </Stack>
    </S.Wrapper>
  )
}

export default LogosSlider
