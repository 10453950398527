import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, IconButton, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import Arrow from "../../assets/arrow.svg"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { keyframes } from "@emotion/react"
import Slider from "react-slick"

export const CustomContainer = styled.div`
  display: flex;
  gap: 5rem;
  img {
    flex: 0 0 auto;
    width: auto;
    height: auto;
  }
  @keyframes move-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes move-right {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation: move-left 30s infinite normal linear;
  &.right {
    animation: move-right 30s infinite normal linear;
  }
`

export const Wrapper = styled.section`
  overflow: unset;
  position: relative;
  padding-top: 45%;
  padding-bottom: 6rem;
  background: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 8rem;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 8rem;
    padding-bottom: 13rem;
  }
`
export const ImageWrapper = styled.div`
  width: 100%;
  //height: 100%;
  z-index: 5;
  img {
    width: 100%;
  }
  top: -290px;
  left: 50%;
  position: absolute;
  transform: translateX(-45%);
  video {
    position: absolute;
    top: 10px;
    left: 7px;
    width: 84%;
    max-width: 871px;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    top: -225px;
    video {
      top: 20px;
      left: 14px;
    }
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    top: -225px;
    left: -20px;
    min-width: 1074px;
    transform: unset;
    video {
      top: 38px;
      left: 36px;
      width: 100%;
    }
  }
`
export const Image = styled(CustomImage)`
  img {
    object-fit: contain !important;
  }
`
