import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import Reveal, { Fade, Slide } from "react-awesome-reveal"
import { keyframes } from "@emotion/react"
import { Link } from "react-scroll"

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: 7rem 0;
  overflow: hidden;
  min-height: calc(100vh - ${({ theme }) => theme.navHeight}px);
  display: flex;
  align-items: center;
`
export const Spacer = styled(SectionWrapper)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.primary.light};
`
export const CustomGrid = styled(Grid)`
  justify-content: flex-end;
  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   .MuiGrid-item {
  //     &:first-child {
  //       order: 2;
  //     }
  //
  //     &:last-child {
  //       order: 1;
  //     }
  //   }
  // }
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: 48px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: 58px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    line-height: 4rem;
  }
`

export const Paragraph = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 21px;
  margin-top: 1em;
  margin-right: 1em;

  &.treez {
    font-family: ${({ theme }) => theme.fonts.primary};
    margin-top: 1em;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0;
  }
`

export const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  &.treez {
    ${({ theme }) => theme.breakpoints.up("md")} {
      max-width: 425px;
      padding-right: 2.2em;
    }
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 598px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 2rem;
  }
`

export const Button = styled(CustomButton)`
  margin-top: 2rem;
  max-width: 165px;
`

export const ImagesWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: -60%;
  display: flex;
  justify-content: center;
  z-index: 2;
  ${({ theme }) => theme.breakpoints.up("md")} {
    left: -40%;
  }
`

export const PhoneImage = styled(CustomImage)`
  height: 100%;
  width: 60%;
  img {
    object-fit: contain !important;
  }
  transform: rotate(90deg);
  position: relative;
  //
  //position: absolute;
  //height: 100%;
  //width: 100%;
  // width: 44%;
  //top: 0;
  //left: -60%;
  // bottom: 0;
  // right: 0;
  //
  ${({ theme }) => theme.breakpoints.down("md")} {
    left: -50%;
    min-width: 617px;
    width: 100%;
  }
`
export const ItemsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 5rem;
  .MuiGrid-container {
    justify-content: center;
  }
  .MuiStack-root {
    max-height: 100%;
    justify-content: space-between;
    scale: 0.5;
    opacity: 0;
  }
  .MuiStack-root > div {
    flex: 1;
    overflow: hidden;
    max-height: 250px;
    position: relative;
  }
  #column1 {
    transform: translateX(50%);
  }
  #column3 {
    transform: translateX(-50%);
  }
  &,
  .MuiStack-root,
  .MuiGrid-root {
    height: 100%;
  }
  img {
    object-fit: contain !important;
  }
  .col10 {
    left: -20%;
  }
  .col11 {
    right: -30%;
    max-height: 140px !important;
  }
  .col12 {
    scale: 1.2;
  }
  .col13 {
    right: -20%;
    max-height: 140px !important;
  }
  .col14 {
    left: -20%;
  }
  .col20 {
    top: -10%;
    left: -15%;
  }
  .col21 {
    left: 30%;
    max-height: 140px !important;
  }
  .col31 {
    max-height: 140px !important;
    left: 17%;
  }
  .col32 {
    max-height: 140px !important;
    left: -15%;
    top: -3%;
  }
  .col33 {
    scale: 1.6;
  }
  .col34 {
    top: 5%;
    left: 15%;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    #column1 {
      transform: translateX(200%);
    }
    #column3 {
      transform: translateX(-200%);
    }
  }
`
export const InnerWrapper = styled.div`
  max-width: 1536px;
  margin: auto;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiGrid-item:nth-child(2) {
      display: none;
    }
  }
`
