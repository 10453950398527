import React, { useState } from "react"
import * as S from "./why-mosaic.styles"
import { Grid } from "@mui/material"
import parse from "html-react-parser"
import gsap from "gsap"
import { useGSAP } from "@gsap/react"

export const WhyMosaic = ({ slides }) => {
  useGSAP(() => {
    let mm = gsap.matchMedia()
    mm.add("(max-width: 899px)", () => {
      const tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: "#why-mosaic",
            duration: 1.5,
            pin: "#why-mosaic",
            scrub: true,
            start: "-71px",
            end: "+=400%",
          },
        })
        .to([`.slider-image0`], {
          duration: 0.5,
        })
        .to([`.slider-image1`, `#text-why-1`], {
          xPercent: -100,
          duration: 0.5,
        })
        .to(
          [`#text-why-0`],
          {
            xPercent: -100,
            duration: 0.5,
          },
          "<"
        )
        .to([`#text-why-2`, `.slider-image2`], {
          xPercent: -100,
          duration: 0.5,
        })
        .to(
          [`#text-why-1`],
          {
            xPercent: -200,
            duration: 0.5,
          },
          "<"
        )
        .to(`.slider-image1`, {
          xPercent: 100,
          duration: 0,
        })
        .to([`#text-why-3`, `.slider-image3`], {
          xPercent: -100,
          duration: 0.5,
        })
        .to(
          [`#text-why-2`],
          {
            xPercent: -200,
            duration: 0.5,
          },
          "<"
        )
        .to(`.slider-image2`, {
          xPercent: 100,
          duration: 0,
        })
    })
    mm.add("(min-width: 900px)", () => {
      const tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: "#why-mosaic",
            duration: 1.5,
            pin: true,
            scrub: true,
            start: "-88px",
            end: "+=400%",
          },
        })
        .to([`.slider-image0`], {
          duration: 0.5,
        })
        .to([`.slider-image1`, `#text-why-1`], {
          yPercent: -100,
          duration: 0.5,
        })
        .to(
          [`#text-why-0`],
          {
            yPercent: -100,
            duration: 0.5,
          },
          "<"
        )
        .to(
          `.slider-image1`,
          {
            borderRadius: 0,
            duration: 0.2,
            delay: 0.3,
          },
          "<"
        )
        .to([`#text-why-2`, `.slider-image2`], {
          yPercent: -100,
          duration: 0.5,
        })
        .to(
          [`#text-why-1`],
          {
            yPercent: -200,
            duration: 0.5,
          },
          "<"
        )
        .to(
          `.slider-image2`,
          {
            borderRadius: 0,
            duration: 0.2,
            delay: 0.3,
          },
          "<"
        )
        .to(`.slider-image1`, {
          yPercent: 100,
          duration: 0,
        })
        .to([`#text-why-3`, `.slider-image3`], {
          yPercent: -100,
          duration: 0.5,
        })
        .to(
          [`#text-why-2`],
          {
            yPercent: -200,
            duration: 0.5,
          },
          "<"
        )
        .to(
          `.slider-image3`,
          {
            borderRadius: 0,
            duration: 0.2,
            delay: 0.3,
          },
          "<"
        )
        .to(`.slider-image2`, {
          yPercent: 100,
          duration: 0,
        })
    })
  }, [])

  if (!slides) return null
  return (
    <S.Spacer>
      <S.Wrapper id="why-mosaic">
        <S.Explore className="explore-next" />
        <S.CustomGrid container>
          <Grid item md={6} sm={12} xs={12}>
            <S.ImagesWrapper>
              {slides?.map(({ title, description, image, logos }, index) => (
                <S.WrapperLeft id={`text-why-${index}`}>
                  <S.InnerWrapper>
                    <div>
                      <S.Tag>Why Mosaic</S.Tag>
                    </div>
                    <div>
                      <S.Title component="h2">{title && parse(title)}</S.Title>
                      <S.Paragraph>{description}</S.Paragraph>
                      {logos && (
                        <S.LogosWrapper>
                          {logos?.map(({ logo }, index) => (
                            <S.Logo
                              key={`logos-${index}`}
                              src={logo?.mediaItemUrl}
                            />
                          ))}
                        </S.LogosWrapper>
                      )}
                    </div>
                  </S.InnerWrapper>
                </S.WrapperLeft>
              ))}
            </S.ImagesWrapper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <S.ImagesWrapper>
              {slides?.map(({ image }, index) => (
                <S.BackgroundImage
                  key={`bg-image${index}`}
                  className={`slider-image${index}`}
                  img={image}
                />
              ))}
            </S.ImagesWrapper>
          </Grid>
        </S.CustomGrid>
      </S.Wrapper>
    </S.Spacer>
  )
}
export default WhyMosaic
