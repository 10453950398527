import styled from "@emotion/styled"

export const SliderWrapper = styled.div`
  pointer-events: none;

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
  margin: 0 1.5rem;

  .swiper {
    overflow: visible;
  }
`
