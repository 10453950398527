import React from "react"
import * as S from "./autoplay-swiper.styles"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import { useMediaQuery, useTheme } from "@mui/material"

const AutoplaySwiper = ({ images, reverseDirection }) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })
  if (!images) return null
  return (
    <S.SliderWrapper>
      <Swiper
        spaceBetween={40}
        slidesPerView={isXs ? 2 : images?.length - 1}
        speed={4000}
        loop={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: reverseDirection,
        }}
        modules={[Autoplay]}
      >
        {images?.map(({ logo }, index) => (
          <SwiperSlide key={`logo-${index}`}>
            <img src={logo.mediaItemUrl} alt={logo.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </S.SliderWrapper>
  )
}

export default AutoplaySwiper
