import React from "react"
import MiscContent from "../layouts/MiscContent"
import PhoneSection from "../layouts/PhoneSection"
import WhyMosaic from "../layouts/WhyMosaic"
import PhrasesSection from "../layouts/PhrasesSection"
import LogosSlider from "../layouts/LogosSlider"
import AdminPortal from "../layouts/AdminPortal"
import GallerySlider from "../layouts/GallerySlider"
import StatsBanner from "../layouts/StatsBanner"
import ArticlesBanner from "../layouts/ArticlesBanner"
import VideoSection from "../layouts/VideoSection"
import AnimatedHero from "../layouts/AnimatedHero"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "PhoneSection":
      return <PhoneSection {...layout} />
    case "PhrasesSection":
      return <PhrasesSection {...layout} />
    case "WhyMosaic":
      return <WhyMosaic {...layout} />
    case "LogosSlider":
      return <LogosSlider {...layout} />
    case "AdminPortal":
      return <AdminPortal {...layout} />
    case "GallerySlider":
      return <GallerySlider {...layout} />
    case "StatsBanner":
      return <StatsBanner {...layout} />
    case "ArticlesBanner":
      return <ArticlesBanner {...layout} />
    case "VideoSection":
      return <VideoSection {...layout} />
    case "AnimatedHero":
      return <AnimatedHero {...layout} />
  }
}
