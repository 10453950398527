import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  background: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 5rem;
    padding-bottom: 10rem;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(52)};
  margin-bottom: 4.25rem;
  text-align: center;
  font-weight: 450;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(72)};
    line-height: ${({ theme }) => theme.typography.pxToRem(80)};
  }
`
export const Content = styled.div`
  overflow: hidden;
  &.background {
    padding: 1rem;
    min-height: 561px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  &.horizontal {
    width: 100%;
    display: flex;
    padding: 2.5rem 0;
    min-height: 520px;
    height: 100%;
    flex-direction: column-reverse;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      flex-direction: row;
      align-items: center;
    }
  }
  &.vertical {
    padding: 2.5rem 1rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    img {
      width: 140%;
      object-position: top left;
    }
  }
  &.horizontal,
  &.vertical {
    border-radius: 16px;
    background: #1c1e1f;
    box-shadow: 0px 4px 120px 0px rgba(0, 0, 0, 0.12);
  }
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18px;
    text-decoration-line: underline;
    margin-top: 1rem;
    display: block;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    &.background {
      max-width: 330px;
      margin-right: 6rem;
      margin-bottom: 3.5rem;
      padding: 0;
      min-height: unset;
    }
    &.vertical {
      padding: 3rem 3.5rem 0;
    }
  }
`
export const CardTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) => theme.typography.pxToRem(30)};
  margin-bottom: 1rem;
  font-weight: 500;
`
export const CardDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.6;
  font-weight: 450;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
`
export const BgImage = styled(CustomBgImage)`
  min-height: 521px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
export const ImageWrapper = styled.div`
  position: relative;
  margin-top: auto;
  padding-top: 3rem;
`
export const Image = styled(CustomImage)`
  &.vertical {
    max-height: 370px;
  }
  &.horizontal {
    width: 110%;
    height: 100%;
    margin-left: -2rem;
    img {
      object-position: right;
      left: unset;
      right: 0;
      top: 40px;
    }
    ${({ theme }) => theme.breakpoints.up("sm")} {
      width: 50%;
      margin-left: unset;
      img {
        width: 571px;
      }
    }
  }
`
export const CustomVideo = styled.video`
  position: absolute;
  top: 4rem;
  width: 75%;
  left: 23px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    left: 18px;
    width: 230px;
  }
`
export const InnerWrapper = styled.div`
  width: 330px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    &.horizontal {
      padding: 0 1.5rem;
    }
  }
`
