import React, { useLayoutEffect, useState } from "react"
import * as S from "./animated-hero.styles"
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material"
import parse from "html-react-parser"
import gsap from "gsap"
import { useGSAP } from "@gsap/react"

import Arrow from "../../assets/arrow-outlined.svg"
import SplitType from "split-type"

export const AnimatedHero = ({
  title,
  tag,
  button,
  bigImage,
  phoneImage,
  video,
}) => {
  const [isPhoneLoaded, setIsPhoneLoaded] = useState(false)
  const [isFlowerLoaded, setIsFlowerLoaded] = useState(false)
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down("lg"))

  useGSAP(() => {
    let videoElement = document.querySelector("#flower-video")
    let mm = gsap.matchMedia()

    if (isPhoneLoaded && isFlowerLoaded) {
      mm.add("(max-width: 899px)", () => {
        const text = new SplitType(".animated-title", { types: "lines, words" })
        gsap
          .timeline({ delay: 0.3 })
          .to(".text-content", {
            autoAlpha: 1,
            duration: 0.2,
          })
          .to(".animated-title", {
            opacity: 1,
          })
          .from(
            ".line .word",
            {
              y: -40,
              ease: "power4.out",
              delay: 0.5,
              opacity: 0,
              stagger: {
                amount: 0.4,
              },
              duration: 0.8,
            },
            "<"
          )
          .fromTo(
            "#button-wrapper",
            {
              y: -40,
            },
            {
              y: 0,
              autoAlpha: 1,
              duration: 0.7,
            }
          )
          .to(".flower-big-image", {
            y: 1,
            x: -1,
            width: "157px",
            opacity: 1,
            duration: 1.5,
          })
          .to(
            ".phone-big-image",
            {
              delay: 0.1,
              opacity: 1,
              duration: 1.5,
            },
            "<"
          )
          .to("#flower-video", {
            opacity: 1,
          })
          .to(
            ".flower-big-image",
            {
              opacity: 0,
              onStart: () => {
                videoElement.play()
              },
            },
            "<"
          )
      })
      mm.add("(min-width: 900px)", () => {
        gsap
          .timeline({ delay: 1 })
          .to(".phone-big-image", {
            width: "50vw",
            duration: 0.7,
          })
          .to(
            ".flower-big-image",
            {
              y: "-20%",
              width: "654px",
              duration: 0.7,
            },
            "<"
          )
          .to(
            ".phone-big-image",
            {
              width: "391px",
              height: "800px",
              left: "unset",
              right: isMd ? "-15%" : "0",
              duration: 0.7,
            },
            "1"
          )
          .to(
            ".flower-big-image",
            {
              right: isMd ? "-15%" : "0",
              left: "unset",
              xPercent: "0",
              width: "206px",
              x: "-289px",
              y: "-217px",
              duration: 0.7,
            },
            "1"
          )
          .fromTo(
            "#text-content",
            {
              y: "30%",
            },
            {
              y: 0,
              opacity: 1,
              duration: 0.3,
            }
          )
          .fromTo(
            "#button-wrapper",
            {
              y: "30%",
            },
            {
              y: 0,
              opacity: 1,
              duration: 0.3,
              delay: 0.2,
            },
            "<"
          )
          .to("#flower-video", {
            opacity: 1,
          })
          .to(
            ".flower-big-image",
            {
              opacity: 0,
              onStart: () => {
                videoElement.play()
              },
            },
            "<"
          )
      })
    }
  }, [isPhoneLoaded, isFlowerLoaded])

  return (
    <S.Wrapper id="overview">
      <S.Ellipse />
      <Container maxWidth="xl">
        <S.BigImage
          className="flower-big-image"
          img={bigImage}
          onLoad={() => {
            setIsFlowerLoaded(true)
          }}
        />
        <S.CustomGrid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            <S.WrapperRight id="text-content">
              <S.Tag>{tag}</S.Tag>
              <S.Title component="h1" className="animated-title">
                {title && parse(title)}
              </S.Title>
              <S.ButtonWrapper id="button-wrapper">
                {button && (
                  <S.Button href={button.url} disableRipple>
                    {button.title}
                  </S.Button>
                )}
                <S.ArrowButton>
                  <Arrow />
                  Intro for Dispensaries & MSOs
                </S.ArrowButton>
              </S.ButtonWrapper>
            </S.WrapperRight>
          </Grid>
        </S.CustomGrid>
        <S.PhoneAnimated
          onLoad={() => {
            setIsPhoneLoaded(true)
          }}
          className="phone-big-image"
          img={phoneImage}
        >
          <div id="white-bg" />
          <video id="flower-video" muted loop playsInline>
            <source src={video?.mediaItemUrl} type="video/mp4" />
          </video>
        </S.PhoneAnimated>
      </Container>
    </S.Wrapper>
  )
}
export default AnimatedHero
