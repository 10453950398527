import React from "react"
import PropTypes from "prop-types"
import * as S from "./phone-section.styles"
import { Container, Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import parse from "html-react-parser"
import gsap from "gsap"
import CustomImage from "../../components/custom-image/custom-image.component"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useGSAP } from "@gsap/react"

export const PhoneSection = ({
  title,
  paragraph,
  button,
  phoneImage,
  items,
}) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })
  const column1 = items?.slice(0, 5)
  const column2 = items?.slice(5, 7)
  const column3 = items?.slice(7, 12)
  useGSAP(() => {
    let mm = gsap.matchMedia()
    let ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger)
      mm.add("(max-width: 899px)", () => {
        const tl = gsap
          .timeline({
            defaults: { ease: "power1.inOut" },
            scrollTrigger: {
              trigger: "#phone-section",
              pin: true,
              scrub: 0.5,
              start: "-112px",
              end: "+=200%",
            },
          })
          .to("#phone-wrapper", {
            duration: 5,
            left: "50%",
          })
          .to(
            "#right-wrapper",
            {
              duration: 3,
              opacity: 0,
              x: "15%",
              scale: 0.9,
              delay: 1,
            },
            "<"
          )
          .to(".phone-image", {
            duration: 3,
            rotate: "0",
            delay: 0.5,
          })
          .to(".stack", { opacity: 1, duration: 1 })
          .to(".stack", { scale: 0.7, duration: 1 }, "<")
          .to(".stack", { scale: 1, duration: 3 })
          .to("#column1", { xPercent: isXs ? -50 : -100, duration: 3 }, "<")
          .to("#column3", { xPercent: isXs ? 50 : 100, duration: 3 }, "<")
          .to(".phone-image", { scale: 0.9, duration: 2 }, "<")
      })
      mm.add("(min-width: 900px)", () => {
        const tl = gsap
          .timeline({
            defaults: { ease: "power1.inOut" },
            scrollTrigger: {
              trigger: "#phone-section",
              pin: true,
              scrub: 0.5,
              start: "-112px",
              end: "+=200%",
            },
          })
          .to("#phone-wrapper", {
            duration: 5,
            left: 0,
          })
          .to(
            "#right-wrapper",
            {
              duration: 3,
              opacity: 0,
              x: "15%",
              scale: 0.9,
              delay: 1,
            },
            "<"
          )
          .to(".phone-image", {
            duration: 3,
            rotate: "0",
            delay: 0.5,
          })
          .to(".stack", { opacity: 1, duration: 1 })
          .to(".stack", { scale: 0.7, duration: 1 }, "<")
          .to(".stack", { scale: 1, duration: 3 })
          .to("#column1", { xPercent: -100, duration: 3 }, "<")
          .to("#column3", { xPercent: 100, duration: 3 }, "<")
          .to(".phone-image", { scale: 0.9, duration: 2 }, "<")
      })
    })
    return () => ctx.revert()
  }, [isXs])

  return (
    <S.Spacer>
      <S.Wrapper id="phone-section">
        <S.ImagesWrapper id="phone-wrapper">
          <S.PhoneImage className="phone-image" img={phoneImage} />
        </S.ImagesWrapper>
        <Container>
          <S.CustomGrid container>
            <Grid item md={6} sm={12} xs={12}>
              <S.WrapperRight id="right-wrapper">
                <S.Title component="h1">{title && parse(title)}</S.Title>
                {paragraph && (
                  <S.Paragraph className="treez">{paragraph}</S.Paragraph>
                )}
                {button && (
                  <S.Button href={button.url} disableRipple>
                    {button.title}
                  </S.Button>
                )}
              </S.WrapperRight>
            </Grid>
          </S.CustomGrid>
        </Container>
        <S.ItemsWrapper>
          <S.InnerWrapper>
            <Grid container spacing={4}>
              <Grid item xs={6} sm={4}>
                <Stack id="column1" className="stack">
                  {column1?.map((item, index) => (
                    <CustomImage
                      key={`col1${index}`}
                      className={`col1${index}`}
                      img={item?.image}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Stack className="stack">
                  {column2?.map((item, index) => (
                    <CustomImage
                      key={`col2${index}`}
                      className={`col2${index}`}
                      img={item?.image}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Stack id="column3" className="stack">
                  {column3?.map((item, index) => (
                    <CustomImage
                      key={`col3${index}`}
                      className={`col3${index}`}
                      img={item?.image}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </S.InnerWrapper>
        </S.ItemsWrapper>
      </S.Wrapper>
    </S.Spacer>
  )
}

PhoneSection.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.object,
  phoneImage: PropTypes.object.isRequired,
  items: PropTypes.array,
}
export default PhoneSection
