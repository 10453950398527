import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../utils/get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_PhoneSection {
            ...PhoneSection
          }
          ... on WpPage_Pagebuilder_Layouts_WhyMosaic {
            ...WhyMosaic
          }
          ... on WpPage_Pagebuilder_Layouts_PhrasesSection {
            ...PhrasesSection
          }
          ... on WpPage_Pagebuilder_Layouts_LogosSlider {
            ...LogosSlider
          }
          ... on WpPage_Pagebuilder_Layouts_AdminPortal {
            ...AdminPortal
          }
          ... on WpPage_Pagebuilder_Layouts_GallerySlider {
            ...GallerySlider
          }
          ... on WpPage_Pagebuilder_Layouts_StatsBanner {
            ...StatsBanner
          }
          ... on WpPage_Pagebuilder_Layouts_ArticlesBanner {
            ...ArticlesBanner
          }
          ... on WpPage_Pagebuilder_Layouts_VideoSection {
            ...VideoSection
          }
          ... on WpPage_Pagebuilder_Layouts_AnimatedHero {
            ...AnimatedHero
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, slug, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []

  useEffect(() => {
    if (seo?.canonical) {
      seo.canonical = `${process.env.GATSBY_WEBSITE_URL}${seo.canonical}`
    }
  }, [])
  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo}>
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
