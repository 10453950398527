import React from "react"
import * as S from "./phrases-section.styles"
import { Grid } from "@mui/material"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

export const PhrasesSection = ({ title, phrases, images }) => {
  const getMedia = item => {
    return item?.video ? (
      <div className="image-wrapper">
        <S.Image img={item?.image} className="image" />
        <video autoPlay muted loop playsInline>
          <source src={item?.video?.mediaItemUrl} type="video/mp4" />
        </video>
      </div>
    ) : (
      <S.Image className="image" img={item?.image} />
    )
  }
  useGSAP(() => {
    const sections = gsap.utils.toArray(".phrase-section")
    sections.forEach(section => {
      gsap.to(section, {
        autoAlpha: 1,
        scrollTrigger: {
          trigger: section,
          start: "top center",
          end: "top center",
          scrub: true,
          onEnter: () => {
            gsap.to(sections, { autoAlpha: 0.5, duration: 0.25 })
            gsap.to(section, { autoAlpha: 1, duration: 0.25 })
          },
          onEnterBack: () => {
            gsap.to(sections, { autoAlpha: 0.5, duration: 0.25 })
            gsap.to(section, { autoAlpha: 1, duration: 0.25 })
          },
        },
      })
    })
  }, [])
  if (!images) return null
  return (
    <S.Wrapper contained>
      <S.Tag>THE FUTURE OF CANNABIS</S.Tag>
      <S.Title>{title}</S.Title>
      <S.CustomGrid container>
        <S.CustomGrid item className="first" xs={12} sm={2}>
          {getMedia(images[0])}
        </S.CustomGrid>
        <S.CustomGrid item className="second" xs={12} sm={4}>
          {getMedia(images[1])}
        </S.CustomGrid>
        <S.CustomGrid item className="third" xs={6} sm={3}>
          {getMedia(images[2])}
        </S.CustomGrid>
        <S.CustomGrid item className="fourth" xs={12} sm={3}>
          {getMedia(images[3])}
        </S.CustomGrid>
        <S.CustomGrid xs={12} sm={8} lg={9} className="fifth">
          {getMedia(images[4])}
        </S.CustomGrid>
        <S.CustomGrid item className="last" xs={6} sm={4} lg={3}>
          {getMedia(images[5])}
        </S.CustomGrid>
      </S.CustomGrid>
      <S.CustomStack spacing={{ xs: 9, sm: 10 }}>
        {phrases?.map(({ phrase }) => (
          <S.Text className={`phrase-section`} key={`phrase-section-${phrase}`}>
            {phrase}
          </S.Text>
        ))}
      </S.CustomStack>
    </S.Wrapper>
  )
}
export default PhrasesSection
