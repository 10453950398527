import React, { useRef, useEffect, useState } from "react"
import * as S from "./gallery-slider.styles"
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../../node_modules/slick-carousel/slick/slick.css"
import Slider from "react-slick"
import Arrow from "../../assets/arrow.svg"
import { graphql, useStaticQuery } from "gatsby"
import { useParallax, Parallax } from "react-scroll-parallax"
import { EffectFade, Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import parse from "html-react-parser"
import { ArrowBack } from "@mui/icons-material"

const GallerySlider = ({ tag, title, description, slides }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })
  if (!slides) return null
  return (
    <S.Wrapper id="examples" contained maxWidth="lg">
      <S.ArrowButton className="prev">
        <ArrowBack />
      </S.ArrowButton>
      <S.Tag>
        <Typography>{tag}</Typography>
      </S.Tag>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      <S.ArrowButton className="next right">
        <ArrowBack />
      </S.ArrowButton>
      <Swiper
        slidesPerView={1}
        spaceBetween={148}
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        modules={[Navigation]}
      >
        {slides?.map(
          (
            {
              url,
              testimonial,
              name,
              position,
              image,
              stats,
              logo,
              testimonialImage,
            },
            index
          ) => (
            <SwiperSlide key={index}>
              <S.Image img={image} />
              <Grid container spacing={4} className="bottom-content">
                <Grid item md={4} sm={12} xs={12}>
                  <S.Logo src={logo.mediaItemUrl} />
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                  <Typography>Website</Typography>
                  <S.Link href={url} target="_blank">
                    {url}
                  </S.Link>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <S.WrapperLeft>
                    {stats?.map((item, index) => (
                      <S.Stat key={`stat${index}`}>
                        <S.Number>{item?.number}</S.Number>
                        <Typography>{item?.description}</Typography>
                      </S.Stat>
                    ))}
                  </S.WrapperLeft>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                  <S.Testimonial>{testimonial}</S.Testimonial>
                  <S.AvatarContainer>
                    <S.TestimonialImage img={testimonialImage} />
                    <div>
                      <S.Name>{name}</S.Name>
                      <S.Position>{position}</S.Position>
                    </div>
                  </S.AvatarContainer>
                </Grid>
              </Grid>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </S.Wrapper>
  )
}

export default GallerySlider
