import React from "react"
import * as S from "./video-section.styles"
import { useMediaQuery, useTheme } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomLink from "../../components/custom-link/custom-link.component"
import { ArrowForward } from "@mui/icons-material"
import Circle from "../../assets/circle.svg"
import parse from "html-react-parser"

const VideoSection = ({ title, video, button, link }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })

  if (!video) return null
  return (
    <S.Wrapper contained maxWidth="xl">
      <S.VideoWrapper>
        <S.Video autoPlay muted loop playsInline>
          <source src={video?.mediaItemUrl} type="video/mp4" />
        </S.Video>
        <S.Content>
          <S.Title>{title}</S.Title>
          <S.ButtonWrapper>
            <CustomButton url={button?.url} className="transparent">
              <Circle />
              {button?.title}
            </CustomButton>
            <CustomLink url={link?.url} className="right">
              {parse(link?.title)}
              <ArrowForward />
            </CustomLink>
          </S.ButtonWrapper>
        </S.Content>
      </S.VideoWrapper>
    </S.Wrapper>
  )
}

export default VideoSection
