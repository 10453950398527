import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
`
export const CustomWrapper = styled.div`
  border-radius: 32px;
  padding: 3rem 1.5rem;
  background: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 5rem;
  }
`
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(38)};
  margin-bottom: 2.25rem;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 732px;
    font-size: ${({ theme }) => theme.typography.pxToRem(68)};
    line-height: ${({ theme }) => theme.typography.pxToRem(72)};
  }
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-style: normal;
  font-weight: 450;
  opacity: 0.65;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: ${({ theme }) => theme.typography.pxToRem(28)};
  &.main {
    margin-bottom: 4.5rem;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 398px;
  }
`

export const Stat = styled.h3`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(52)};
  line-height: ${({ theme }) => theme.typography.pxToRem(60)};
  font-weight: 400;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(68)};
    line-height: ${({ theme }) => theme.typography.pxToRem(72)};
  }
`

export const StatWrapper = styled.div`
  width: 100%;
  padding-top: 1.25rem;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: 307px;
  }
`
