import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid, IconButton, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import EllipseIcon from "../../assets/ellipse.svg"
export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  overflow: hidden;
  padding: 8rem 0 0 0;
  &,
  .MuiContainer-root {
    position: relative;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0;
    &,
    .MuiContainer-root {
      height: 100vh;
      min-height: 1000px;
    }
    .MuiContainer-root {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`
export const BigImage = styled(CustomImage)`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: translateX(-50%);
  opacity: 0;
  img {
    object-fit: contain !important;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 834px;
    opacity: 1;
  }
`
export const CustomGrid = styled(Grid)``
export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text.primary};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(52)};
  opacity: 0;
  min-height: 128px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    line-height: 4rem;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(80)};
    line-height: ${({ theme }) => theme.typography.pxToRem(82)};
    opacity: 1;
    min-height: unset;
  }
`
export const Tag = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: normal;
  margin-bottom: 1rem;
`

export const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 710px;
    opacity: 0;
  }
`

export const Button = styled(CustomButton)`
  max-width: 165px;
  display: none;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: block;
  }
`
export const ButtonWrapper = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1.75rem;
  opacity: 0;
  margin-bottom: 3.5rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 3rem;
    margin-bottom: 0;
  }
`
export const PhoneAnimated = styled(CustomBgImage)`
  overflow: visible;
  height: 615px;
  width: 303px;
  margin: auto;
  opacity: 0;
  video {
    width: 273px;
    height: 219px;
    left: 15px;
    top: 13px;
    object-fit: cover;
    opacity: 0;
    z-index: -2;
    position: relative;
  }
  #white-bg {
    top: 1px;
    left: 13px;
    background: #f9f9fb;
    position: absolute;
    width: 94%;
    height: 86%;
    opacity: 1;
    z-index: -2;
    border-radius: 50px;
  }
  img {
    object-position: center 10%;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    #white-bg {
      left: 19px;
    }
    video {
      width: 351px;
      left: 20px;
      height: 284px;
      top: 18px;
    }
  }
`
export const Ellipse = styled(EllipseIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50vw;
  height: 100%;
`
export const ArrowButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.primary};
  gap: 0.75rem;
  font-size: 1.25rem;
  &,
  :hover {
    background: transparent;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    gap: 0.5rem;
    svg {
      width: 48px;
      height: 48px;
    }
  }
`
