import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid, Stack, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-top: 8.75rem;
  padding-bottom: 10rem;
`

export const CustomGrid = styled(Grid)`
  video {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &.first {
    display: flex;
    align-items: center;
    .image-wrapper {
      position: relative;
      width: 100%;
      height: 423px;
    }
    .image {
      width: 286px;
      height: 423px;
      z-index: 1;
      position: relative;
      img {
        object-fit: contain !important;
      }
    }
    video {
      position: absolute;
      top: 10px;
      left: 12px;
      width: 155px;
      height: 336px;
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      .image-wrapper {
        left: -25%;
      }
      .image {
        width: 200px;
        height: 423px;
      }
      video {
        width: 109px;
        top: 21px;
        left: 8px;
      }
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      display: none;
    }
  }
  &.first,
  &.second,
  &.third,
  &.fourth,
  &.last {
    position: relative;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      .image,
      video {
        position: absolute;
      }
    }
  }
  &.second {
    .image,
    video {
      width: 100%;
      margin-left: 10%;
    }
    ${({ theme }) => theme.breakpoints.up("lg")} {
      min-height: 657px;
      .image,
      video {
        width: 130%;
        left: unset;
        margin-left: unset;
      }
    }
  }
  &.third {
    left: -6%;
    .image {
      width: 100%;
    }
    ${({ theme }) => theme.breakpoints.up("sm")} {
      left: 5%;
    }
  }
  &.fourth {
    .image-wrapper {
      position: relative;
      top: -11%;
    }
    .image {
      width: 400px;
      z-index: 1;
      position: relative;
    }
    video {
      top: 2%;
      width: 345px;
      height: 230px;
      left: 7%;
    }
    ${({ theme }) => theme.breakpoints.up("md")} {
      .image {
        width: 656px;
      }
      video {
        width: 545px;
        height: 388px;
      }
      .image-wrapper {
        top: -20%;
      }
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
      display: none;
    }
  }
  &.fifth {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      order: 4;
      .image {
        margin-left: 10%;
      }
    }
  }
  &.last {
    .image {
      width: 100%;
      top: -50%;
      ${({ theme }) => theme.breakpoints.down("lg")} {
        top: -20%;
      }
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      order: 2;
      left: 5%;
      margin-top: 6rem;
    }
  }
  &.third .gatsby-image-wrapper,
  &.last .gatsby-image-wrapper {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      min-height: 423px;
      overflow: visible;
      img {
        min-width: 286px;
        object-position: left;
      }
    }
  }
`

export const Tag = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-transform: uppercase;
  color: #056640;
  text-align: center;
  line-height: normal;
  letter-spacing: 1.1px;
  font-style: normal;
  font-size: ${({ theme }) => theme.typography.pxToRem(22)};
  font-weight: 900;
  margin-bottom: 1rem;
`

export const Title = styled.h2`
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  line-height: ${({ theme }) => theme.typography.pxToRem(52)};
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(72)};
    line-height: 80px;
    margin-bottom: 5rem;
  }
`
export const Image = styled(CustomImage)`
  //position: absolute;
`
export const CustomStack = styled(Stack)`
  max-width: 852px;
  margin-left: auto;
  margin-right: auto;
`
export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(40)};
  font-style: normal;
  font-weight: 500;
  opacity: 0.35;
  transition: 0.3s all ease;
  :hover {
    opacity: 1;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    line-height: 4rem;
  }
`
